
import Vue from "vue";
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer.vue";
import SignUpProfileFill from "@/components/SignUp/SignUpProfileFill.vue";

export default Vue.extend({
  name: "SignUpProfileFillPage",
  components: {
    Header,
    Footer,
    SignUpProfileFill
  }
});
